import * as React from "react"
import { Link, graphql } from 'gatsby'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const FriendsAndResourcesPage = ({data}) => 
{
  const entry = data.prismicFriendsAndResourcesPage

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full block object-cover py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-8 sm:px-16 py-16 md:py-36">
            <div className="leading-snug text-2xl text-white font-poppins font-semibold">
              <h2 className="mb-8">
                <span className="bg-white text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.heading}</span>
              </h2>
              <p className="ml-3">{entry.data.text}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white py-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-center font-poppins font-semibold text-diamond text-2xl">
            <h2>{entry.data.friends_heading}</h2>
          </div>
        </div>
      </div>

      <div className="w-full bg-white pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
          {entry.data.friends.map((entry, i) => {
            return (
              <div key={`ff_${i}`}>
                <figure className="mb-8">
                  <GatsbyImage className="w-full h-full object-cover rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                </figure>
                <div className="font-poppins font-semibold text-orange text-xl leading-tight mb-4">
                  <h2>{entry.heading1}</h2>
                </div>
                <div className="font-poppins max-w-none prose prose-h2:text-diamond prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </div>
                <div className="mt-4 font-poppins font-medium text-orange leading-snug">
                  <a href={entry.url} title={entry.heading1} target="_blank" rel="nofollow noopener">
                    Go to website &#62;
                  </a>
                </div>
              </div>
            )
          })}
          </div>
        </div>
      </div>

      {entry.data.featured_friends.map((entry, i) => {
      return (
      <div key={`ff_${i}`} className="w-full bg-white">
        <div className={i % 2 === 0 ? "w-full py-16 bg-diamond rounded-tl-[133px]" : "w-full py-16 bg-diamond-10 rounded-br-[133px]"}>
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="sm:text-left flex-none sm:flex justify-between items-top gap-6 md:gap-0 lg:gap-12 xl:gap-6">
              <div className="flex justify-center w-full sm:w-4/12 lg:w-2/12 mb-12 sm:mb-0">
                <figure className="flex items-center justify-center w-36 h-36 lg:w-40 lg:h-40 bg-white p-3 rounded-full">
                  <GatsbyImage className="w-2/3 h-2/3 inline-block mx-auto bg-white p-3" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                </figure>
              </div>  
              <div className="w-full sm:w-8/12 lg:w-10/12">
                <div className={i % 2 === 0 ? "text-center font-poppins font-semibold text-white text-2xl mb-6" : "text-center font-poppins font-semibold text-diamond text-2xl mb-6"}>
                  <h2>{entry.heading1}</h2>
                </div>
                <div className={i % 2 === 0 ? "font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-white prose-li:text-white leading-snug" : "font-poppins max-w-none prose prose-h2:text-diamond prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug" }>
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </div>
                <div className={i % 2 === 0 ? "mt-8 font-poppins font-medium text-white leading-snug" : "mt-8 font-poppins font-medium text-diamond leading-snug"}>
                  <a href={entry.url} title={entry.heading1} target="_blank" rel="nofollow noopener">
                    Check out website &#62;
                  </a>
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>
      )
      })}

      <div className="w-full bg-white pt-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="text-center font-poppins font-semibold text-diamond text-2xl">
            <h2>{entry.data.resources_heading}</h2>
          </div>
        </div>
      </div>

      {entry.data.resources.map((entry, i) => {
        if (i === 0) {
          return (
            <div key={`resources_1_${i}`} className="w-full bg-white pt-4">
              <div className="w-10/12 mx-auto max-w-screen-xl">
                <div className="mb-8 text-center font-poppins font-semibold text-orange text-xl">
                  <h2><a href={entry.file.url} title={entry.heading1} rel="nofollow noopener">{entry.heading1}</a></h2>
                </div>
                <div className="flex flex-col sm:flex-row justify-between gap-8 sm:gap-16">
                  <div className="w-full sm:w-1/2 order-2 sm:order-1">
                    <div className="font-poppins max-w-none prose prose-h2:text-white prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond-80 leading-snug">
                      <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 order-1 sm:order-2">
                    <figure className="mb-0 sm:mb-8">
                      <GatsbyImage className="w-full h-full object-cover rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}

      <div className="w-full bg-white pt-16 pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16">
          {entry.data.resources.map((entry, i) => {
            if (i > 0) {
              return (
                <div key={`rg_${i}`}>
                  <figure className="mb-8">
                    <GatsbyImage className="w-full h-full object-cover rounded-tr-[27px] rounded-tl-[27px] rounded-bl-[27px]" image={entry.image1.gatsbyImageData} alt={entry.image1.alt ?? ''} /> 
                  </figure>
                  <div className="font-poppins font-semibold text-orange text-xl leading-tight mb-4">
                    <h2><a href={entry.file.url} title={entry.heading1} rel="nofollow noopener">{entry.heading1}</a></h2>
                  </div>
                  <div className="font-poppins max-w-none prose prose-h2:text-diamond prose-h2:font-semibold prose-p:font-medium prose-p:text-diamond-80 prose-li:text-diamond leading-snug">
                    <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                  </div>
                </div>
              )
            }
          })}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicFriendsAndResourcesPage {
    data {
      meta_description
      page_title
      heading
      image {
        alt
        gatsbyImageData
      }
      text
      friends_heading
      friends {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text1 {
          richText
        }
        url
      }
      featured_friends {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text1 {
          richText
        }
        url
      }
      resources_heading
      resources {
        heading1
        image1 {
          alt
          gatsbyImageData
        }
        text1 {
          richText
        }
        file {
          url
        }
      }
    }
  }
}
`

export default FriendsAndResourcesPage
